var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('name')
                ? _vm.errors.first('name')
                : '',"variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : '',"label":'Nombre'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"name","placeholder":"Ingrese el nombre","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : ''},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('coordinator')
                ? _vm.errors.first('coordinator')
                : '',"variant":_vm.showValidation && _vm.errors.first('coordinator') ? 'danger' : '',"label":'Coordinador'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"coordinator","placeholder":"Ingrese el nombre","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('coordinator') ? 'danger' : ''},model:{value:(_vm.form.coordinator),callback:function ($$v) {_vm.$set(_vm.form, "coordinator", $$v)},expression:"form.coordinator"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('address')
                ? _vm.errors.first('address')
                : '',"variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : '',"label":'Dirección'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"address","placeholder":"Estado, ciudad","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : ''},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('t-input',{staticClass:"mt-2",attrs:{"name":"address2","placeholder":"Calle, edificio, etc.","autocomplete":"off","disabled":_vm.loading,"type":"text"},model:{value:(_vm.form.address2),callback:function ($$v) {_vm.$set(_vm.form, "address2", $$v)},expression:"form.address2"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('email')
                ? _vm.errors.first('email')
                : '',"variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : '',"label":'Correo electrónico'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"data-vv-validate-on":"input","name":"email","placeholder":"Ingrese el correo electrónico","autocomplete":"off","disabled":_vm.loading,"type":"email","variant":_vm.showValidation && _vm.errors.first('email') ? 'danger' : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('instagram')
                ? _vm.errors.first('instagram')
                : '',"variant":_vm.showValidation && _vm.errors.first('instagram') ? 'danger' : '',"label":'Usuario de Instagram'}},[_c('t-input',{attrs:{"name":"instagram","placeholder":"Ingrese el usuario de Instagram","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('instagram') ? 'danger' : ''},model:{value:(_vm.form.instagram),callback:function ($$v) {_vm.$set(_vm.form, "instagram", $$v)},expression:"form.instagram"}})],1),_c('div',{staticClass:"flex justify-start my-4"},[_c('p',[_vm._v("Mostrar en la app")]),_c('t-toggle',{staticClass:"mx-3",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.toggleVisibility(!_vm.visible)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}),_c('p',[_vm._v("Sí")])],1),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"flex justify-start"},[_c('h1',{staticClass:"subtitle-text text-black"},[_vm._v(" Contactos de la academia ")]),_c('icon',{staticClass:"rounded-full bg-red-1100 text-white text-3xl p-2 my-auto ml-3",staticStyle:{"cursor":"pointer","width":"28px","height":"28px"},attrs:{"icon":"plus"},on:{"click":function($event){_vm.academyContacts = _vm.academyContacts.concat([
                    {
                      key: Date.now(),
                      name: '',
                      phone: ''
                    }
                  ])}}})],1),_vm._l((_vm.academyContacts),function(element,index){return _c('div',{key:element.id
                  ? new Date().toLocaleDateString() + element.id
                  : new Date().toLocaleDateString() + element.key},[_c('div',{staticClass:"flex md:hidden my-3"},[_c('div',{staticClass:"w-1/6 my-auto mx-auto"},[_c('icon',{staticClass:"rounded-full bg-red-1100 text-white text-3xl p-2 my-auto ml-3",staticStyle:{"cursor":"pointer","width":"28px","height":"28px"},attrs:{"icon":"times"},on:{"click":function($event){return _vm.deleteAcademyContact(element)}}})],1),_c('div',{staticClass:"w-5/6"},[_c('t-input-group',{staticClass:"w-full mx-1",attrs:{"label":'Nombre',"feedback":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-name'
                          : element.key + 'academyContact-name'
                      )
                        ? _vm.errors.first(
                            element.id
                              ? element.id + 'academyContact-name'
                              : element.key + 'academyContact-name'
                          )
                        : '',"variant":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-name'
                          : element.key + 'academyContact-name'
                      )
                        ? 'danger'
                        : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":element.id
                          ? element.id + 'academyContact-name'
                          : element.key + 'academyContact-name',"placeholder":"Ingrese el nombre del contacto","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation &&
                        _vm.errors.first(
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        )
                          ? 'danger'
                          : ''},on:{"change":function($event){return _vm.debouncedUpdate(index)}},model:{value:(element.name),callback:function ($$v) {_vm.$set(element, "name", $$v)},expression:"element.name"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 mx-1",attrs:{"label":'Teléfono',"feedback":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone'
                      )
                        ? _vm.errors.first(
                            element.id
                              ? element.id + 'academyContact-phone'
                              : element.key + 'academyContact-phone'
                          )
                        : '',"variant":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone'
                      )
                        ? 'danger'
                        : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required|phone'),expression:"'simple_required|phone'"}],attrs:{"data-vv-validate-on":"input","name":element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone',"placeholder":"Ingrese el teléfono del contacto","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation &&
                        _vm.errors.first(
                          element.id
                            ? element.id + 'academyContact-phone'
                            : element.key + 'academyContact-phone'
                        )
                          ? 'danger'
                          : ''},on:{"change":function($event){return _vm.debouncedUpdate(index)}},model:{value:(element.phone),callback:function ($$v) {_vm.$set(element, "phone", $$v)},expression:"element.phone"}})],1)],1)]),_c('div',{staticClass:"hidden md:flex flex-col md:flex-row items-end"},[_c('div',{staticClass:"flex md:w-1/2"},[_c('icon',{staticClass:"rounded-full bg-red-1100 text-white text-3xl p-2 mt-9 ml-3",staticStyle:{"cursor":"pointer","width":"28px","height":"28px"},attrs:{"icon":"times"},on:{"click":function($event){return _vm.deleteAcademyContact(element)}}}),_c('t-input-group',{staticClass:"w-full mx-1",attrs:{"label":'Nombre',"feedback":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-name'
                          : element.key + 'academyContact-name'
                      )
                        ? _vm.errors.first(
                            element.id
                              ? element.id + 'academyContact-name'
                              : element.key + 'academyContact-name'
                          )
                        : '',"variant":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-name'
                          : element.key + 'academyContact-name'
                      )
                        ? 'danger'
                        : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":element.id
                          ? element.id + 'academyContact-name'
                          : element.key + 'academyContact-name',"placeholder":"Ingrese el nombre del contacto","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation &&
                        _vm.errors.first(
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        )
                          ? 'danger'
                          : ''},on:{"change":function($event){return _vm.debouncedUpdate(index)}},model:{value:(element.name),callback:function ($$v) {_vm.$set(element, "name", $$v)},expression:"element.name"}})],1)],1),_c('t-input-group',{staticClass:"ml-auto w-full md:w-1/2 mx-1",attrs:{"label":'Teléfono',"feedback":_vm.showValidation &&
                    _vm.errors.first(
                      element.id
                        ? element.id + 'academyContact-phone'
                        : element.key + 'academyContact-phone'
                    )
                      ? _vm.errors.first(
                          element.id
                            ? element.id + 'academyContact-phone'
                            : element.key + 'academyContact-phone'
                        )
                      : '',"variant":_vm.showValidation &&
                    _vm.errors.first(
                      element.id
                        ? element.id + 'academyContact-phone'
                        : element.key + 'academyContact-phone'
                    )
                      ? 'danger'
                      : ''}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required|phone'),expression:"'simple_required|phone'"}],attrs:{"data-vv-validate-on":"input","name":element.id
                        ? element.id + 'academyContact-phone'
                        : element.key + 'academyContact-phone',"placeholder":"Ingrese el teléfono contacto","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation &&
                      _vm.errors.first(
                        element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone'
                      )
                        ? 'danger'
                        : ''},on:{"change":function($event){return _vm.debouncedUpdate(index)}},model:{value:(element.phone),callback:function ($$v) {_vm.$set(element, "phone", $$v)},expression:"element.phone"}})],1)],1)])})],2)],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1 relative"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('categories')
                ? _vm.errors.first('categories')
                : '',"variant":_vm.showValidation && _vm.errors.first('categories') ? 'danger' : '',"label":'Categorías - Subcategorías'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"categories","id":"categories","placeholder":"Seleccione una opción","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.categories,"textAttribute":"name","valueAttribute":"index","multiple":true,"closeOnSelect":false,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('categories') ? 'danger' : ''},model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}})],1),_c('div',{staticClass:"flex flex-col items-center justify-around mt-2 mb-10"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_logo')
                  ? _vm.errors.first('url_logo')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_logo') ? 'danger' : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Foto del escudo")]),_c('cropper-upload',{attrs:{"file":_vm.form.url_logo,"externalUrl":typeof _vm.form.url_logo === 'string' ? _vm.form.url_logo : null,"title":"Foto del escudo","aspectRatio":1,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_logo", $event)}}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_field')
                  ? _vm.errors.first('url_field')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_field') ? 'danger' : '',"label":'Foto de la sede'}},[_c('cropper-upload',{attrs:{"file":_vm.form.url_field,"externalUrl":typeof _vm.form.url_field === 'string' ? _vm.form.url_field : null,"title":"Foto de la sede","aspectRatio":16 / 9,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_field", $event)}}})],1)],1),(_vm.deletable)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar academia")])],1):_vm._e()],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }