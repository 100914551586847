<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombre'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.name"
                name="name"
                placeholder="Ingrese el nombre"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('coordinator')
                  ? errors.first('coordinator')
                  : ''
              "
              :variant="
                showValidation && errors.first('coordinator') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Coordinador'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.coordinator"
                name="coordinator"
                placeholder="Ingrese el nombre"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('coordinator') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('address')
                  ? errors.first('address')
                  : ''
              "
              :variant="
                showValidation && errors.first('address') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Dirección'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.address1"
                name="address"
                placeholder="Estado, ciudad"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('address') ? 'danger' : ''
                "
              />
              <t-input
                class="mt-2"
                v-model="form.address2"
                name="address2"
                placeholder="Calle, edificio, etc."
                autocomplete="off"
                :disabled="loading"
                type="text"
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('email')
                  ? errors.first('email')
                  : ''
              "
              :variant="showValidation && errors.first('email') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Correo electrónico'"
            >
              <t-input
                v-validate="'required|email'"
                data-vv-validate-on="input"
                v-model="form.email"
                name="email"
                placeholder="Ingrese el correo electrónico"
                autocomplete="off"
                :disabled="loading"
                type="email"
                :variant="
                  showValidation && errors.first('email') ? 'danger' : ''
                "
              />
            </t-input-group>
            <t-input-group
              :feedback="
                showValidation && errors.first('instagram')
                  ? errors.first('instagram')
                  : ''
              "
              :variant="
                showValidation && errors.first('instagram') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Usuario de Instagram'"
            >
              <t-input
                v-model="form.instagram"
                name="instagram"
                placeholder="Ingrese el usuario de Instagram"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('instagram') ? 'danger' : ''
                "
              />
            </t-input-group>

            <div class="flex justify-start my-4">
              <p>Mostrar en la app</p>
              <t-toggle
                class="mx-3"
                v-model="visible"
                @click="toggleVisibility(!visible)"
                :disabled="loading"
              />
              <p>Sí</p>
            </div>

            <div class="mt-5">
              <div class="flex justify-start">
                <h1 class="subtitle-text text-black">
                  Contactos de la academia
                </h1>
                <icon
                  style="cursor: pointer; width: 28px; height: 28px"
                  icon="plus"
                  class="rounded-full bg-red-1100 text-white text-3xl p-2 my-auto ml-3"
                  @click="
                    academyContacts = academyContacts.concat([
                      {
                        key: Date.now(),
                        name: '',
                        phone: ''
                      }
                    ])
                  "
                />
              </div>

              <div
                v-for="(element, index) in academyContacts"
                :key="
                  element.id
                    ? new Date().toLocaleDateString() + element.id
                    : new Date().toLocaleDateString() + element.key
                "
              >
                <div class="flex md:hidden my-3">
                  <div class="w-1/6 my-auto mx-auto">
                    <icon
                      style="cursor: pointer; width: 28px; height: 28px"
                      icon="times"
                      class="rounded-full bg-red-1100 text-white text-3xl p-2 my-auto ml-3"
                      @click="deleteAcademyContact(element)"
                    />
                  </div>
                  <div class="w-5/6">
                    <t-input-group
                      class="w-full mx-1"
                      :label="'Nombre'"
                      :feedback="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        )
                          ? errors.first(
                              element.id
                                ? element.id + 'academyContact-name'
                                : element.key + 'academyContact-name'
                            )
                          : ''
                      "
                      :variant="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        )
                          ? 'danger'
                          : ''
                      "
                    >
                      <t-input
                        v-validate="'simple_required'"
                        data-vv-validate-on="input"
                        v-model="element.name"
                        :name="
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        "
                        @change="debouncedUpdate(index)"
                        placeholder="Ingrese el nombre del contacto"
                        autocomplete="off"
                        :disabled="loading"
                        type="text"
                        :variant="
                          showValidation &&
                          errors.first(
                            element.id
                              ? element.id + 'academyContact-name'
                              : element.key + 'academyContact-name'
                          )
                            ? 'danger'
                            : ''
                        "
                      />
                    </t-input-group>
                    <t-input-group
                      class="w-full md:w-1/2 mx-1"
                      :label="'Teléfono'"
                      :feedback="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-phone'
                            : element.key + 'academyContact-phone'
                        )
                          ? errors.first(
                              element.id
                                ? element.id + 'academyContact-phone'
                                : element.key + 'academyContact-phone'
                            )
                          : ''
                      "
                      :variant="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-phone'
                            : element.key + 'academyContact-phone'
                        )
                          ? 'danger'
                          : ''
                      "
                    >
                      <t-input
                        v-validate="'simple_required|phone'"
                        data-vv-validate-on="input"
                        v-model="element.phone"
                        :name="
                          element.id
                            ? element.id + 'academyContact-phone'
                            : element.key + 'academyContact-phone'
                        "
                        @change="debouncedUpdate(index)"
                        placeholder="Ingrese el teléfono del contacto"
                        autocomplete="off"
                        :disabled="loading"
                        type="text"
                        :variant="
                          showValidation &&
                          errors.first(
                            element.id
                              ? element.id + 'academyContact-phone'
                              : element.key + 'academyContact-phone'
                          )
                            ? 'danger'
                            : ''
                        "
                      />
                    </t-input-group>
                  </div>
                </div>
                <div class="hidden md:flex flex-col md:flex-row items-end">
                  <div class="flex md:w-1/2">
                    <icon
                      style="cursor: pointer; width: 28px; height: 28px"
                      icon="times"
                      class="rounded-full bg-red-1100 text-white text-3xl p-2 mt-9 ml-3"
                      @click="deleteAcademyContact(element)"
                    />
                    <t-input-group
                      class="w-full mx-1"
                      :label="'Nombre'"
                      :feedback="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        )
                          ? errors.first(
                              element.id
                                ? element.id + 'academyContact-name'
                                : element.key + 'academyContact-name'
                            )
                          : ''
                      "
                      :variant="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        )
                          ? 'danger'
                          : ''
                      "
                    >
                      <t-input
                        v-validate="'simple_required'"
                        data-vv-validate-on="input"
                        v-model="element.name"
                        :name="
                          element.id
                            ? element.id + 'academyContact-name'
                            : element.key + 'academyContact-name'
                        "
                        @change="debouncedUpdate(index)"
                        placeholder="Ingrese el nombre del contacto"
                        autocomplete="off"
                        :disabled="loading"
                        type="text"
                        :variant="
                          showValidation &&
                          errors.first(
                            element.id
                              ? element.id + 'academyContact-name'
                              : element.key + 'academyContact-name'
                          )
                            ? 'danger'
                            : ''
                        "
                      />
                    </t-input-group>
                  </div>

                  <t-input-group
                    class="ml-auto w-full md:w-1/2 mx-1"
                    :label="'Teléfono'"
                    :feedback="
                      showValidation &&
                      errors.first(
                        element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone'
                      )
                        ? errors.first(
                            element.id
                              ? element.id + 'academyContact-phone'
                              : element.key + 'academyContact-phone'
                          )
                        : ''
                    "
                    :variant="
                      showValidation &&
                      errors.first(
                        element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone'
                      )
                        ? 'danger'
                        : ''
                    "
                  >
                    <t-input
                      v-validate="'simple_required|phone'"
                      data-vv-validate-on="input"
                      v-model="element.phone"
                      :name="
                        element.id
                          ? element.id + 'academyContact-phone'
                          : element.key + 'academyContact-phone'
                      "
                      @change="debouncedUpdate(index)"
                      placeholder="Ingrese el teléfono contacto"
                      autocomplete="off"
                      :disabled="loading"
                      type="text"
                      :variant="
                        showValidation &&
                        errors.first(
                          element.id
                            ? element.id + 'academyContact-phone'
                            : element.key + 'academyContact-phone'
                        )
                          ? 'danger'
                          : ''
                      "
                    />
                  </t-input-group>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 mx-1 relative">
            <t-input-group
              :feedback="
                showValidation && errors.first('categories')
                  ? errors.first('categories')
                  : ''
              "
              :variant="
                showValidation && errors.first('categories') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categorías - Subcategorías'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.categories"
                name="categories"
                id="categories"
                placeholder="Seleccione una opción"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="categories"
                textAttribute="name"
                valueAttribute="index"
                :multiple="true"
                :closeOnSelect="false"
                :clearable="false"
                :variant="
                  showValidation && errors.first('categories') ? 'danger' : ''
                "
              ></t-rich-select>
            </t-input-group>
            <div class="flex flex-col items-center justify-around mt-2 mb-10">
              <t-input-group
                :feedback="
                  showValidation && errors.first('url_logo')
                    ? errors.first('url_logo')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_logo') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
              >
                <label class="block text-left font-bold">Foto del escudo</label>
                <cropper-upload
                  :file.sync="form.url_logo"
                  :externalUrl="
                    typeof form.url_logo === 'string' ? form.url_logo : null
                  "
                  title="Foto del escudo"
                  :aspectRatio="1"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('url_field')
                    ? errors.first('url_field')
                    : ''
                "
                :variant="
                  showValidation && errors.first('url_field') ? 'danger' : ''
                "
                class="w-full md:w-1/2 md:mr-0 md:pr-2"
                :label="'Foto de la sede'"
              >
                <cropper-upload
                  :file.sync="form.url_field"
                  :externalUrl="
                    typeof form.url_field === 'string' ? form.url_field : null
                  "
                  title="Foto de la sede"
                  :aspectRatio="16 / 9"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </t-input-group>
            </div>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar academia</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import debounce from "lodash.debounce"
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      academyContacts: [],
      form: {
        name: null,
        coordinator: null,
        address1: null,
        address2: null,
        categories: [],
        phone: null,
        email: null,
        url_logo: null,
        url_field: null
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    formCategories() {
      return JSON.parse(JSON.stringify(this.form.categories))
    },
    categories() {
      const data = []
      const categories = this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
      const subcategories = this.$store.getters["global/getItems"](
        "subcategory",
        "unpaginated_elements"
      )

      for (let category of categories) {
        for (let subcategory of subcategories) {
          data.push({
            index: category.id + "$$" + subcategory.id,
            id_subcategory: subcategory.id,
            id_category: category.id,
            name: category.name + " - " + subcategory.name
          })
        }
      }
      return data
    },
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    debouncedUpdate() {},
    updateAcademyContact(index) {
      if (this.academyContacts[index].id) {
        this.$emit("updateContact", this.academyContacts[index])
      }
    },
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deleteAcademyContact(element) {
      if (element.id) {
        this.$store
          .dispatch("global/changeStatus", {
            payload: {
              id: element.id,
              status: 2
            },
            route: "/academy_contact"
          })
          .then((response) => {
            if ([200, 201].includes(response.code))
              this.academyContacts = this.academyContacts.filter(
                (x) => x.key !== element.key
              )
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify)
            })
          })
      } else {
        if (element.key || element.key === 0) {
          this.academyContacts = this.academyContacts.filter(
            (x) => x.key !== element.key
          )
        }
      }
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.url_logo) {
            return this.$snotify.error(
              "La foto del escudo es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          if (!this.form.url_field) {
            return this.$snotify.error(
              "La foto de la sede es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          }
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente a la academia.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/academy",
                module: "academy"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/academias")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/subcategory/all", "subcategory", true)
    this.getItems("/category/all", "category", true)
    this.debouncedUpdate = debounce(this.updateAcademyContact, 200)
  },
  watch: {
    formCategories(newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        // An item was removed, find that item
        for (let index in oldValue) {
          let removed_index = newValue.findIndex((x) => x == oldValue[index])
          if (removed_index === -1)
            this.$emit("removeAcademyCategory", {
              id_category: oldValue[index].split("$$")[0],
              id_subcategory: oldValue[index].split("$$")[1]
            })
        }
      }
    }
  }
}
</script>
